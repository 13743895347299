<template>
  <div class="col-md-9">
    <div class="card" style="border: none" id="print">
      <div class="card-body">
        <div class="text-center">
          <img src="/images/iona-clinic-resize.png" height="70" />
          <h4
            style="
              font-weight: bold;
              margin-top: 20px;
              text-transform: uppercase;
            "
          >
            Lembar Stok Opname
            <hr
              style="
                border-bottom: 2px solid green;
                margin-right: 200px;
                margin-left: 200px;
                margin-top: 5px;
                margin-bottom: 5px;
              "
            />
          </h4>
        </div>

        <div class="row mb-2">
          <div class="col-12">
            <b-table bordered :items="items" :fields="fields">
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <!-- <template #cell(warehouse)="data">
                <div v-for="(a, index) in data.item.warehouse" :key="index">
                  <span v-if="data.item.warehouse[index] !== ''">{{
                    data.item.warehouse[index] + "-" + data.item.rack[index]
                  }}</span>
                </div>
              </template> -->
              <template #cell(checking_stock)="data">
                <span v-if="data.item.checking_stock > 0">{{
                  data.item.checking_stock
                }}</span>
              </template>
              <template #cell(difference_stock)="data">
                <span v-if="data.item.checking_stock > 0">{{
                  data.item.difference_stock
                }}</span>
              </template>
            </b-table>
          </div>
          <div class="col-md-12 mt-1 pt-0">
            <span class="h5 d-block">Keterangan :</span>
            <span>{{ data.notes }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
export default {
  props: {
    data: Object,
    //   paymentRemuneration:Array,
    //   pieceRemuneration:Array,
    //   taxRemuneration:Array,
    //   tableDisplay:Object,
    //   employee:Object,
    //   subTotalPayment:Number,
    //   subTotalPiece:Number,
    //   subTotalTax:Number,
    //   address:Object,
  },

  data() {
    return {
      // Data
      data: [],
      // Other
      imgLoaded: false,
      // User Access
      btn: false,

      fields: [
        {
          key: "number",
          label: "#",
        },
        {
          key: "medicine_name",
          label: "Nama Obat & Alkes",
        },
        {
          key: "current_stock",
          label: "Stok Di Sistem",
        },
        {
          key: "checking_stock",
          label: "Stok Fisik",
        },
        {
          key: "difference_stock",
          label: "Selisih Stok",
        },
        // {
        //   key: "warehouse",
        //   label: "Gudang - Rak",
        // },
      ],
      items: [],
      // Pagination
      perPage: 2000,
      currentPage: 1,
      totalRows: 1,
    };
  },

  methods: {
    async pagination() {
      let response = await module.paginate(
        `detail-stock-opnames/by-stock/${this.$route.params.id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;

      // this.items.forEach((el) => {
      //   // warehouse
      //   el.warehouse.forEach((val,index) => {
      //     console.log(val,index);
      //     let arr = [];
      //     let exist;

      //     if (arr != []) {
      //       exist = arr.find((x) => x.warehouse == val);

      //       console.log(exist);
      //       if (!exist) {
      //         arr.push(val);
      //       }
      //     } else {
      //       arr.push(val);
      //     }

      //     el.warehouse = arr;
          
      //   });
        
        
        
      // });
      // console.log(this.items,"items");
    },
  },

  mounted() {
    this.pagination();
  },
};
</script>

<style>
</style>